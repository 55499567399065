<template>
  <header>
    <div
      class="he-ui-bar-icon d-flex align-items-center justify-content-center"
      @click="onclickBack">
      <span
        class="material-icons-outlined close-session-icon">keyboard_arrow_left</span>
    </div>
    <div class="he-ui-font-normal-bold text-format-header">{{ headerText }}</div>
    <div
      class="he-ui-bar-icon d-flex align-items-center"
      @click="onclickHome">
      <img src="../assets/logo-heuristik.png" alt="logo_heuristik" />
    </div>
  </header>
</template>

<script>
import { MAIN_PAGE_ROUTE_NAME } from '@/router/routes'

export default {
  props: {
    headerText: {
      type: String,
      required: true
    },
    goBack: {
      type: Function,
      default: null
    },
    goHome: {
      type: Function,
      default: null
    }
  },
  methods: {
    onclickBack () {
      if (this.goBack) this.goBack()
      else this.$router.go(-1)
    },
    onclickHome () {
      this.goHome ? this.goHome() : this.$router.push({ name: MAIN_PAGE_ROUTE_NAME })
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
  border-radius: 14px;
  background: $white-color;

  .text-format-header {
    color: $grey-color;
  }

  .he-ui-bar-icon {
    width: 3rem;
    height: 3rem;
    padding: 6px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    z-index: 99;
    cursor: pointer;
    img {
        width: 100%;
      }
  }
}
</style>
