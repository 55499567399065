<template>
  <div
    class="he-ui-info-box he-ui-font-small-bold d-flex align-items-start justify-content-center align-items-center"
    :class="`status-${status}`">
    <span class="material-icons-outlined me-4">{{ getIcon }}</span>
    <div>
      <span class="me-2 text-uppercase">{{ $t(Object.values(HANDS)[selectedHand].token) }}</span>
      <span class="text-uppercase">- {{ getFingerName }}</span>
    </div>
  </div>
</template>

<script>
import { FINGERS_LEFT_HAND, FINGERS_RIGHT_HAND, HANDS } from '@/constants/constants'

export default {
  props: {
    status: {
      type: String,
      validator (value) {
        return [
          'initial',
          'finished'
        ].includes(value)
      }
    },
    hand: {
      type: Number,
      default: null
    },
    finger: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      HANDS,
      selectedHand: this.hand,
      selectedfinger: this.finger
    }
  },
  computed: {
    isStatusInitial () {
      return this.status === 'initial'
    },
    getIcon () {
      return this.isStatusInitial ? 'check_box' : 'perm_device_information'
    },
    getFingerName () {
      const hand = this.selectedHand ? FINGERS_RIGHT_HAND : FINGERS_LEFT_HAND
      const fingers = Object.values(hand)
      return this.$t(fingers.find(finger => finger.index === this.selectedfinger).token)
    }
  },
  watch: {
    hand (value) {
      this.selectedHand = value
    },
    finger (value) {
      this.selectedfinger = value
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-info-box {
  border-radius: 8px;

  &.status-initial {
    background: rgb(54 138 255 / 11%);
    color: $blue-color;
  }

  &.status-finished {
    background: rgba(52, 199, 89, 0.08);
    color: $green-color;
  }
}
</style>
