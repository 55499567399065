export default () => {
  const sendDataToParentClient = (HHID, isNew = false) => {
    const heuristikMessage = {
      HHID: HHID,
      isNew: isNew
    }
    window.parent.postMessage(heuristikMessage, '*')
  }

  return {
    sendDataToParentClient
  }
}
