
export default () => {
  const getHands = (hand, finger, savedFingers = []) => {
    const saved = savedFingers.find(fing => fing === finger)
    return Number.isInteger(saved) ? require(`../assets/mini-hands/${hand}-${finger}-saved.png`) : require(`../assets/mini-hands/${hand}-${finger}.png`)
  }

  return {
    getHands
  }
}
