<template>
  <div
    :class="{'hands--displayed': isNewPatient}"
    class="he-ui-core-hand-img d-flex align-items-center justify-content-between flex-column mt-5">
    <div class="position-relative he-ui-hand-container mt-5">
      <div
        v-for="fingerprint in getFingerPrints"
        :key="fingerprint.index"
        class="position-absolute he-ui-core-hand-fingerprint-img"
        :style="{'top': fingerprint.top, 'left': fingerprint.left}">
        <img
          :src="getFingerprintImage(fingerprint.index)"
          alt="selected_finger_hand" />
      </div>
      <img
        :src="getSelectedHand"
        alt="selected_finger_hand" />
      </div>
      <div
        v-if="isNewPatient"
        class="he-ui-hands-status-wrapper mt-4 d-flex">
      </div>
  </div>
  <div
    v-if="isNewPatient"
    class="he-ui-mini-hands-wrapper d-flex">
    <div
      v-for="(n, finger) in 5"
      :key="finger"
      class="he-ui-mini-hand me-2">
      <img :src="getHands(HANDS.LEFT.index, finger, savedFingersData)" alt="" />
    </div>
    <div
      v-for="finger in [...Array(10).keys()].slice(5).reverse()"
      :key="finger"
      class="he-ui-mini-hand me-2">
      <img :src="getHands(HANDS.RIGHT.index, finger, savedFingersData)" alt="" />
    </div>
  </div>
</template>

<script>
import { HANDS } from '@/constants/constants'
import handsCompositionFunction from '@/composition/handsCompositionFunction'

export default {
  props: {
    selectedFinger: {
      type: Number,
      require: true
    },
    selectedHand: {
      type: Number,
      require: true
    },
    showNumberOfFingers: {
      type: Boolean,
      default: true
    },
    showAllFingerprints: {
      type: Boolean,
      default: true
    },
    isNewPatient: {
      type: Boolean,
      default: false
    },
    savedFingers: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { getHands } = handsCompositionFunction()

    return {
      getHands
    }
  },
  data () {
    return {
      fingerprints: [
        [
          { top: '31%', left: '-1%', index: 0 },
          { top: '-7%', left: '25%', index: 1 },
          { top: '-15%', left: '46%', index: 2 },
          { top: '-10%', left: '66%', index: 3 },
          { top: '6%', left: '84%', index: 4 }
        ],
        [
          { top: '6%', left: '-1%', index: 5 },
          { top: '-10%', left: '17%', index: 6 },
          { top: '-15%', left: '37%', index: 7 },
          { top: '-7%', left: '57%', index: 8 },
          { top: '31%', left: '86%', index: 9 }
        ]
      ],
      finger: this.selectedFinger,
      hand: this.selectedHand,
      HANDS,
      savedFingersData: this.savedFingers
    }
  },
  computed: {
    getSelectedHand () {
      if (this.showNumberOfFingers) {
        const selectedHand = this.hand
        return require('../assets/hands/hand-' + selectedHand + '.png')
      }
      return require('../assets/hands/right_hand_without_numbers.png')
    },
    getFingerPrints () {
      if (this.showAllFingerprints) return this.fingerprints[this.hand]
      return [this.fingerprints[HANDS.RIGHT.index][3]]
    }
  },
  watch: {
    selectedFinger (value) {
      this.finger = value
    },
    selectedHand (value) {
      this.hand = value
    },
    savedFingers (newVal) {
      this.savedFingersData = newVal
    }
  },
  methods: {
    getFingerprintImage (index) {
      return index === this.finger ? require('../assets/hands/fingerprint-selected.png') : require('../assets/hands/fingerprint.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-core-hand-fingerprint-img {
  width: 55px;
  height: 55px;
  @media (max-width: 1180px) {
    width: 40px;
    height: 40px;
  }
  @media (max-height: 780px) {
    width: 40px;
    height: 40px;
  }
}
@media (max-height: 780px) {
  .he-ui-hand-container {
    height: 330px;
  }
}
.he-ui-mini-hand {
  width: 45px;
  height: 60px;
  img {
    max-width: 100%;
  }
  &:nth-child(5) {
    margin-right: 30px!important;
  }
}
</style>
