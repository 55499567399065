<template>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="container-fluid">
      <div class="row">
        <div
          :class="col1"
          class="d-flex align-items-center justify-content-center flex-column">
          <slot name="col1"></slot>
        </div>
        <div :class="col2">
          <slot name="col2"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    col1: {
      type: String,
      default: ''
    },
    col2: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-default-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@for $i from 1 through 100 {
  .he-ui-col-#{$i} {
    width: unquote($i + "%");
  }
}
</style>
