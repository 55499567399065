<template>
  <div
    class="he-ui-fingerprint-preview-wrapper position-relative"
  >
    <div
      :class="{'blue': blue}"
      class="he-ui-fingerprint-preview"
    >
      <img :src="image || defaultImage" alt="fingerprint" />
    </div>
    <div
      v-if="showHintImage"
      class="position-absolute he-ui-mini-fingerprint">
      <img src="../assets/hands/fingerprint-selected.png" alt="hint_fingerprint" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: require('../assets/default_fingerprint.png')
    },
    showHintImage: {
      type: Boolean,
      default: false
    },
    blue: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imageData: this.image
    }
  },
  watch: {
    image (value) {
      this.imageData = value
    }
  },
  computed: {
    defaultImage () {
      return require('../assets/default_fingerprint.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-fingerprint-preview {
  overflow: hidden;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  img {
    max-width: 100%;
  }
}
.he-ui-mini-fingerprint {
  top: 365px;
  right: 50px;
  width: 73px;
  height: 73px;
  img {
    max-width: 100%;
  }
}
.blue {
  border: 5px solid $blue-color;
  background: rgba(54, 138, 255, 0.08);
}

@media (max-height: 810px) {
  .he-ui-fingerprint-preview {
    width: 350px;
    height: 350px;
    img {
      max-width: 50%;
    }
  }
  .he-ui-mini-fingerprint {
    top: 283px;
    right: 30px;
  }
}
@media (max-height: 716px) {
  .he-ui-fingerprint-preview {
    width: 300px;
    height: 300px;
  }
  .he-ui-mini-fingerprint {
    top: 228px;
    right: 13px;
  }
}
</style>
