<template>
  <modal v-on="$attrs">
    <template #body>
      <div class="he-ui-scanning-modal-image mb-4">
        <img src="../assets/scanning.gif" alt="animation" />
        <div clasS="he-ui-scanning-modal-text">{{ $t('main.scanning') }}...</div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
.he-ui-scanning-modal {
  background-color: #EAEDF1!important;
}
.he-ui-scanning-modal-text {
  font-weight: 600;
  font-size: 1.2rem;
}
.he-ui-scanning-modal-image {
  width: 350px;
  height: 350px;

  img {
    max-width: 100%;
  }
}
</style>
