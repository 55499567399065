<template>
  <Header
    :headerText="$t('main.identification')"
    :goBack="goBack" />
  <DefaultContainer
    col1="col-5"
    col2="col-7">
    <template #col1>
      <preview
        v-if="status == 'finished'"
        showHintImage
        :image="imageData"/>
      <Hand
        v-else
        :selectedHand="HANDS.RIGHT.index"
        :selectedFinger="FINGERS_RIGHT_HAND.INDEXFINGER.index"
        :showNumberOfFingers="false"
        :showAllFingerprints="false"
        :isNewPatient="false" />
    </template>
    <template #col2>
      <scanning-results
        class="he-ui-scan-main-finger-wrapper"
        :status="status"
        :hand="HANDS.RIGHT.index"
        :finger="FINGERS_RIGHT_HAND.INDEXFINGER.index"
        :saveDisabled="!hasResultImage"
        @accept="onAccept"
        @scan="onScan"
        @replace="onReplace" />
    </template>
  </DefaultContainer>
  <portal to="modal-place">
    <ErrorModal
      v-if="showErrorModal"
      :title="$t('main.error')"
      :subtitle="$t('main.fingerprint_not_found')"
      image="patient-error"
      :buttons="[{text: $t('main.try_again'), template: 'blue', handler: this.onTryAgain}]"
      @close="() => showModal = false"/>
  </portal>
  <portal to="modal-place">
    <SuccesModal
      v-if="showSuccessModal"
      :patientId="patientId"
      showButton="true"
      :message="$t('main.accept')"
      :onClicked="closeSuccessModal"/>
  </portal>
  <portal to="modal-place">
    <Scanning
      v-if="loading"
      class="he-ui-scanning-modal"/>
  </portal>
</template>

<script>
import { FINGERS_RIGHT_HAND, HANDS } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME, SCAN_MULTIFINGER_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DefaultContainer from '@/components/DefaultContainer'
import ErrorModal from '@/components/ErrorModal'
import Hand from '@/components/Hand'
import Header from '@/components/Header'
import Preview from '@/components/Preview'
import Scanning from '@/components/Scanning'
import ScanningResults from '@/components/ScanningResults'
import SuccesModal from '@/components/SuccesModal'
import fingerprintReader from '@/composition/fingerprintReader'
import mixins from '@/composition/mixins'

export default {
  name: 'DefaultScanning',
  components: {
    DefaultContainer,
    ErrorModal,
    Hand,
    Header,
    Preview,
    Scanning,
    ScanningResults,
    SuccesModal
  },
  setup () {
    const { imageData, clean, captureFingerprint, hasResultImage, resetFingerprint, stopCapture } = fingerprintReader()
    const { sendDataToParentClient } = mixins()

    return {
      imageData,
      clean,
      captureFingerprint,
      hasResultImage,
      resetFingerprint,
      sendDataToParentClient,
      stopCapture
    }
  },
  data () {
    return {
      FINGERS_RIGHT_HAND,
      HANDS,
      showErrorModal: false,
      showSuccessModal: false,
      status: 'initial',
      loading: false,
      patientId: ''
    }
  },
  computed: {
    ...mapGetters([
      'getAllSensors'
    ])
  },
  methods: {
    ...mapActions([
      'loginPatient',
      'registerFingerprint'
    ]),
    ...mapMutations([
      'resetPatientId'
    ]),
    goBack () {
      return this.status === 'initial' ? this.$router.push({ name: MAIN_PAGE_ROUTE_NAME }) : this.changeStatus()
    },
    changeStatus () {
      this.status = 'initial'
    },
    onAccept () {
      this.loading = true
      const data = {
        sensorId: this.getAllSensors[0].id,
        fingerprintBase64: this.clean(this.imageData),
        fingerNumber: FINGERS_RIGHT_HAND.INDEXFINGER.index
      }
      this.loginPatient(data)
        .then((response) => {
          this.patientId = response.hhid
          // Connect with hospital's software
          this.sendDataToParentClient(this.patientId)
          this.showSuccessModal = true
        })
        .catch(() => {
          this.showErrorModal = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    onTryAgain () {
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    onScan () {
      this.status = 'finished'
      this.captureFingerprint()
    },
    onReplace () {
      this.resetFingerprint()
      this.captureFingerprint()
    },
    closeSuccessModal () {
      this.canLeavePage = true
      this.showLeavingModal = false
      this.showSuccessModal = false
      this.resetPatientId()
      this.$router.push({
        name: MAIN_PAGE_ROUTE_NAME
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-scan-main-finger-wrapper {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-right: 100px;
}
</style>
