<template>
  <div class="he-ui-scanning-results bg-white d-flex align-items-center flex-column">
    <div class="he-ui-font-regular mb-4">
      {{ getPrimaryLabel }}
    </div>
    <info-box
      class="he-ui-info-box mt-2 mb-4"
      :status="status"
      :hand="hand"
      :finger="finger" />
    <Button
      v-if="isInitialStatus"
      class="mb-3"
      template="blue"
      rounded
      uppercase
      :text="$t('main.scan')"
      :onClick="onScan" />
    <div class="he-ui-font-regular mt-2 mb-4">
      {{ getSecondaryLabel }}
    </div>
    <div class="d-flex mt-3 gap-3">
      <Button
        template="white"
        rounded
        uppercase
        :text="!isInitialStatus ? $t('main.replace') : $t('main.manual')"
        :onClick="!isInitialStatus ? onReplace : navigateToManualScan" />
      <Button
        v-if="!isInitialStatus"
        class="ms-4"
        template="blue"
        rounded
        uppercase
        :disabled="disableAcceptButton"
        :text="$t('main.accept')"
        :onClick="onAccept" />
    </div>
  </div>
</template>

<script>
import { IS_NEW_PATIENT } from '@/constants/constants'
import { SCAN_MULTIFINGER_PAGE_ROUTE_NAME } from '@/router/routes'
import Button from '@/components/MainButton'
import InfoBox from '@/components/InfoBox'
import fingerprintReader from '@/composition/fingerprintReader'

export default {
  components: {
    Button,
    InfoBox
  },
  props: {
    status: {
      type: String,
      validator (value) {
        return [
          'initial',
          'finished'
        ].includes(value)
      }
    },
    hand: {
      type: Number,
      default: 0
    },
    finger: {
      type: Number,
      default: 0
    },
    saveDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { isLowQuality } = fingerprintReader()

    return {
      isLowQuality
    }
  },
  computed: {
    getPrimaryLabel () {
      return this.isInitialStatus ? this.$t('main.identify_patient_scanning_title') + ':' : this.$t('main.scanning_finished') + ':'
    },
    getSecondaryLabel () {
      return this.isInitialStatus ? this.$t('main.if_not_possible') + ':' : this.$t('main.is_fingerprint_valid')
    },
    isInitialStatus () {
      return this.status === 'initial'
    },
    disableAcceptButton () {
      return (this.saveDisabled) || (this.isLowQuality && localStorage.getItem(IS_NEW_PATIENT))
    }
  },
  methods: {
    navigateToManualScan () {
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    onScan () {
      this.$emit('scan')
    },
    onAccept () {
      this.$emit('accept')
    },
    onReplace () {
      this.$emit('replace')
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-scanning-results {
  padding: 76px 116px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  margin-right: 100px;

  .he-ui-info-box {
    width: 527px;
    height: 72px;
  }
}
</style>
